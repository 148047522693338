import {useEffect} from "react";

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(10, 10)
  }, [])


  return (
    <div className="px-56 my-20">
      <h1 className="text-center font-bold text-3xl">Terms and Conditions</h1>
      <h3 className="font-bold text-xl mt-4">Terms and Conditions Agreement</h3>
      <h3 className="font-bold text-xl mt-4">Effective: September 04, 2024</h3>
      <p className="mt-14">PLEASE READ THE TERMS AND CONDITIONS CAREFULLY. THIS AGREEMENT ("AGREEMENT") CONSTITUTES A LEGAL AGREEMENT BETWEEN YOU AND DOM FOOD, LLC, A COMPANY REGISTERED IN THE REPUBLIC OF ARMENIA, INCLUDING ITS SUBSIDIARIES AND AFFILIATED COMPANIES (COLLECTIVELY REFERRED TO AS "DOM FOOD", "WE", "US", OR "OUR").</p>
      <p className="mt-10">SECTION 12 OF THIS AGREEMENT CONTAINS PROVISIONS THAT GOVERN HOW CLAIMS BETWEEN YOU AND US ARE RESOLVED, INCLUDING CLAIMS THAT AROSE BEFORE THE EFFECTIVE DATE OF THIS AGREEMENT. SPECIFICALLY, SECTION 12 INCLUDES OUR ARBITRATION AGREEMENT, WHICH, WITH LIMITED EXCEPTIONS, REQUIRES DISPUTES BETWEEN US TO BE RESOLVED THROUGH MANDATORY AND FINAL ARBITRATION. UNLESS YOU OPT OUT OF THE ARBITRATION AGREEMENT: (1) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AND SEEK RELIEF AGAINST US ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING; AND (2) YOU ARE WAIVING YOUR RIGHT TO SEEK RELIEF IN A COURT OF LAW AND TO HAVE A JURY TRIAL ON YOUR CLAIMS. THE ARBITRATION AGREEMENT MAY AFFECT YOUR RIGHT TO PARTICIPATE IN PENDING OR PROPOSED CLASS ACTION LITIGATION. PLEASE SEE SECTION 12 FOR MORE INFORMATION ABOUT THIS ARBITRATION AGREEMENT, ITS POSSIBLE EFFECTS, AND HOW TO OPT OUT OF THE ARBITRATION AGREEMENT.</p>


  <p className="text-xl font-bold mt-4">1. Acceptance of this Agreement</p>
  <p><br/></p>
  <p>1.1 Overview of Services: DOM FOOD, including its wholly-owned subsidiary DOM FOOD G&amp;C, LLC, provides an online platform that connects you with other consumers, restaurants, and/or other businesses, as well as independent delivery contractors (&ldquo;Contractors&rdquo;). Our software enables consumers to place orders for food and/or other goods from various restaurants and businesses, either for delivery or pickup. When a delivery order is placed, our software notifies Contractors about the delivery opportunity and facilitates the completion of the delivery to the consumer. For pickup orders, the software communicates with the customer regarding the availability of the order for pickup. DOM FOOD is not a restaurant, delivery service, or food preparation business.</p>
  <p><br/></p>
  <p>1.2 Acceptance of Terms: By accessing our website at https://www.dom-food.com, installing or using our DOM FOOD mobile application, using any other software provided by DOM FOOD, or accessing any information, function, or service available or enabled by DOM FOOD (&quot;Service&quot;), or by completing the DOM FOOD account registration process, you, your heirs, assigns, and successors (&quot;you&quot; or &quot;your&quot;) agree to be bound by the terms of this Agreement.</p>
  <p><br/></p>
  <p>1.3 Representations and Warranties: By using the Services, you represent and warrant that:</p>
  <p>&nbsp; &nbsp;&nbsp;&bull; (a) You have read, understand, and agree to be bound by this Agreement: You acknowledge that you have reviewed the terms of this Agreement and agree to adhere to them.</p>
  <p>&nbsp; &nbsp;&nbsp;&bull; (b) You are of legal age: You are of legal age in the jurisdiction where you reside to form a binding contract with DOM FOOD.</p>
  <p>&nbsp; &nbsp;&nbsp;&bull; (c) Authority to Enter into Agreement: You have the authority to enter into this Agreement personally and, if applicable, on behalf of any organization on whose behalf you have created an account, and you can bind such organization to the terms of this Agreement.</p>
  <p><br/></p>
  <p>1.4 Definition of &quot;User&quot;: The terms &quot;User&quot; and &quot;Users&quot; refer to all individuals and entities who access or use the Services, including, but not limited to, any organizations that register accounts or otherwise access or use the Services through their representatives.</p>
  <p><br/></p>
  <p>1.5 Access and Use: If you do not agree to be bound by the terms of this Agreement, you must not access or use the Services. Your continued use of the Services constitutes acceptance of and agreement to the terms of this Agreement.</p>
  <p><br/></p>
  <p className="text-xl font-bold mt-4">2. Modifications</p>
  <p><br/></p>
  <p>2.1 Right to Modify: Subject to Section 12(k) of this Agreement, DOM FOOD reserves the right to modify the terms and conditions of this Agreement or any policies related to the Software or Services at any time. Such modifications will become effective immediately upon posting of the updated version of this Agreement through the Services.</p>
  <p><br/></p>
  <p>2.2 Notification of Changes: When changes are made to this Agreement, DOM FOOD will post the updated version on our website or through the relevant Services. It is your responsibility to review the updated Agreement regularly to stay informed about any changes.</p>
  <p><br/></p>
  <p>2.3 Continued Use Constitutes Acceptance: Your continued use of the Services after the posting of any changes to this Agreement constitutes your acceptance of and agreement to the modified terms. If you do not agree with the changes, you must stop using the Services and discontinue your use of the Software.</p>
  <p><br/></p>
  <p>2.4 Effective Date of Changes: The updated terms and conditions will be effective as of the date they are posted. DOM FOOD will indicate the effective date of the updated Agreement at the top of the page.</p>
  <p><br/></p>
      <p>2.5 Review and Acknowledgement: It is important to review the terms and conditions regulrly. By continuing to access or use the Services after any modifications have been posted, you acknowledge that you have read, understood, and agreed to the revised terms.</p>
      <p className="text-xl font-bold mt-4 mb-4">3. Additional Terms and Policies</p>

<p>3.1 Binding Agreement: By using the Services, you agree to be bound by this Agreement and acknowledge that you have read and understood the terms. Your use of the Services also signifies your agreement to the collection, use, and disclosure of your personal information as described in DoorDash’s Privacy Policy, which is incorporated into this Agreement by reference.</p>
      <p><br/></p>
      <p>3.2 Additional Policies: You agree to comply with any additional DOM FOOD policies for users that are published on our website or mobile application. These additional policies may govern specific aspects of the Services or provide additional guidelines that complement this Agreement.</p>
      <p><br/></p>
<p>3.3 Incorporation of Additional Terms: Certain features or functionalities of our Services may be subject to additional terms and conditions. These additional terms are incorporated into this Agreement by reference and will apply to your use of those specific features. By using such features, you agree to abide by these additional terms and conditions.</p>
      <p><br/></p>
<p>3.4 Regular Review of Policies: To ensure your comfort and the protection of your rights, we recommend that you regularly review our Privacy Policy and any additional policies or terms published on our website or mobile application. These documents may be updated from time to time, and staying informed of any changes is essential for understanding how they may impact your use of the Services.</p>
      <p><br/></p>
<p>3.5 Conflicts with Additional Terms: In the event of any conflict between the terms of this Agreement and any additional terms or policies, the terms of this Agreement will govern unless explicitly stated otherwise. If additional terms or policies are specific to certain features or services, those terms will apply to the extent of the conflict.</p>
      <p><br/></p>

      <p className="text-xl font-bold mt-4">4. Rules and Prohibitions</p>
      <p className="mt-4 mb-4">Without limiting other rules and prohibitions in this Agreement, by using the Services, you agree to the following:</p>
      <p>4.1 Lawful Use: You will use the Services solely for lawful purposes. You must not use the Services to send, store, or distribute any material that is unlawful, deceptive, fraudulent, or harmful. This includes, but is not limited to, content that could harm other Users, DOM FOOD employees, or the broader community.</p>
      <p><br/></p>
<p>4.2 Compliance with Laws: You will comply with all applicable laws and regulations when using the Services. This includes respecting copyrights, trade secrets, privacy rights, publicity rights, and other intellectual property or proprietary rights of third parties.</p>
      <p><br/></p>
      <p>4.3 Authorized Access: You will access the Services only through means explicitly authorized by DOM FOOD. Unauthorized access methods or attempts to bypass security measures are prohibited.
      </p>
      <p><br/></p>
<p>4.4 No Impersonation or Misrepresentation: You will not use another User’s account or impersonate any person or entity. This includes forging or manipulating headers or identifiers to disguise the origin of any content transmitted through the Services. Misrepresentation or falsification of your identity is prohibited.</p>
      <p><br/></p>
<p>4.5 No Nuisance or Inconvenience: You will not use the Services in a manner that causes nuisance, annoyance, or inconvenience to others. This includes, but is not limited to, spamming, harassment, or other disruptive behaviors.</p>
      <p><br/></p>
      <p>
        4.6 Prohibition on Harmful Activities: You will not engage in any activities that could damage, disable, overburden, or impair the Services, including any attempts to interfere with the proper functioning of the Services or to disrupt the use of the Services by other Users.</p>
      <p><br/></p>
      <p>4.7 No Unauthorized Use of Software: You will not use or distribute unauthorized copies of software, tools, or scripts that could impact the performance or security of the Services.</p>
      <p><br/></p>
      <p>4.8 Reporting Violations: If you encounter any violations of these rules or prohibitions, you should report them using the mechanisms provided within the Services. DOM FOOD LLC will review and address such reports in accordance with its policies.</p>
      <p><br/></p>

      <p className="text-xl font-bold mt-4 mb-4">5. Contractors and Merchants Are Independent</p>
      <p>5.1 Platform Role: You understand and agree that DOM FOOD provides a technology platform designed to connect you with independent food service providers and other entities that offer products through the Services (“Merchants”), as well as independent third-party contractors who provide delivery services (“Contractors”). DOM FOOD acts solely as an intermediary facilitating the transmission of orders from Users to Merchants and coordinating delivery through Contractors.</p>
      <p><br/></p>
      <p>5.2 No Direct Involvement: DOM FOOD does not prepare food, offer delivery services, or otherwise engage in retail activities. The services provided by DOM FOOD under this Agreement do not include any food preparation or retail services. DOM FOOD is not involved in the delivery business and is not considered a common carrier. Our role is limited to providing a platform that facilitates communication and transactions between Users, Merchants, and Contractors.</p>
      <p><br/></p>
      <p>5.3 No Responsibility for Actions or Omissions: You acknowledge and agree that DOM FOOD is not responsible or liable for any actions or omissions of any Merchant or Contractor. DOM FOOD does not assess or guarantee the suitability, legality, or ability of any Merchant or Contractor. This includes not verifying whether the photographs or images displayed through the Services accurately reflect the food prepared by Merchants or delivered by Contractors.</p>
      <p><br/></p>
      <p>5.4 Merchant and Contractor Responsibilities: The Merchant is responsible for the preparation and safety of the food. DOM FOOD does not verify the Merchant’s compliance with applicable laws or regulations concerning food safety or quality. For delivery orders, the Contractor is responsible for transporting the products from the Merchant’s location to your designated delivery address based on your instructions.</p>
      <p><br/></p>
      <p>5.5 Title and Ownership: You agree that title to the goods you purchase passes from the Merchant to you at the Merchant’s location. For delivery orders, the Contractor will be directed to deliver the products to your specified location. Neither the Contractor nor DOM FOOD holds title to or acquires any ownership interest in the goods ordered through the Services. Ownership and title of the goods remain with the Merchant until the transaction is complete.</p>
      <p><br/></p>
      <p>5.6 Limitation of Liability: DOM FOOD is not liable for any issues arising from the food preparation, safety, or accuracy of the representations made by the Merchants. Additionally, DOM FOOD is not responsible for any delivery issues or other problems related to the Contractors’ performance.</p>
      <p className="text-xl font-bold mt-4 mb-4">6. User Account</p>
      <p>6.1 Account Registration: To access certain parts of the Services, you may be required to register for an account. During the registration process and whenever you use the Services, you must provide accurate, current, and complete information. It is your responsibility to update your account information to keep it accurate, current, and complete.</p>
      <p><br/></p>
      <p>6.2 Authorized Use: You are the sole authorized user of any account you create through the Services. You are solely and fully responsible for all activities that occur under your account and password. This includes maintaining the confidentiality of your password and account information.</p>
      <p><br/></p>
      <p>6.3 Responsibility for Unauthorized Use: You agree to monitor your account to prevent unauthorized use by minors or other individuals. You accept full responsibility for any unauthorized use of your password or account. You may not authorize others to use your User status, and you may not assign or transfer your User account to any other person or entity without explicit permission from DOM FOOD.</p>
      <p><br/></p>
      <p>6.4 Reporting Unauthorized Use: If you suspect that your account or password is being used without authorization, you must notify DOM FOOD immediately. Failure to report unauthorized use may result in liability for any damages or losses incurred by DOM FOOD or third parties due to such unauthorized use.</p>
      <p><br/></p>
      <p>6.5 Liability for Unauthorized Use: DOM FOOD will not be liable for any losses, damages, liabilities, expenses, or fees incurred by you or any third party arising from unauthorized use of your account, regardless of whether you have notified us of such unauthorized use.</p>
      <p><br/></p>
      <p>6.6 Account Suspension and Termination: DOM FOOD reserves the right to suspend or terminate your account if you provide information that is untrue, inaccurate, not current, or incomplete, or if we have reasonable grounds to suspect that such information is incorrect. Additionally, DOM FOOD may refuse any and all current or future use of the Services (or any portion thereof) if you are found to have violated this Agreement.</p>
      <p><br/></p>
      <p>6.7 Previous Removal or Banning: You agree not to create an account or use the Services if you have been previously removed or banned from using the Services by DOM FOOD. Any attempt to circumvent such removal or ban will result in further action, including potential legal remedies.</p>
      <p><br/></p>
      <p>6.8 Account Security: You are responsible for implementing and maintaining reasonable security measures to protect your account. This includes using strong passwords and safeguarding your login credentials from unauthorized access.</p>
      <p><br/></p>
      <p className="text-xl font-bold mt-4 mb-4">7. User Content</p>
      <p><br/></p>
      <p>7.1 User Content: DOM FOOD provides interactive opportunities through the Services, such as the ability to post user ratings, reviews, and other content ("User Content"). You warrant that you own or have the necessary rights to submit the User Content you provide, and that it complies with all applicable legal standards and respects the rights of third parties. Specifically, you affirm that your User Content:
        • (i) Does not infringe on any copyright, trademark, patent, trade secret, or other intellectual property or proprietary rights of any third party.
        • (ii) Does not contain material that is false, misleading, defamatory, or unlawful.
        • (iii) Does not violate any applicable laws or regulations.
        • (iv) Adheres to the terms of this Agreement.</p>
      <p><br/></p>
      <p>7.2 License Grant: By submitting User Content, you grant DOM FOOD a perpetual, irrevocable, worldwide, royalty-free, and non-exclusive license to use, reproduce, modify, publish, translate, distribute, perform, and display such content, including creating derivative works based on it. You also permit DOM FOOD to use your profile information, such as your username and ratings history, in connection with your User Content.</p>
      <p><br/></p>
      <p>7.3 Content Responsibility: You are solely responsible for the User Content you post and the consequences of doing so. You agree to indemnify and hold DOM FOOD harmless from any claims, damages, liabilities, losses, costs, or expenses (including reasonable attorneys' fees) arising out of or related to your User Content. DOM FOOD reserves the right, but not the obligation, to monitor, edit, or remove any content or activity at its sole discretion. DOM FOOD assumes no liability for any User Content posted by you or any third party.</p>
      <p><br/></p>
      <p>7.4 User Conduct: You agree not to use the Services to:
        • (i) Transmit spam, bulk, or unsolicited communications.
        • (ii) Impersonate DOM FOOD or any other person or entity.
        • (iii) Forge headers or manipulate identifiers to disguise the origin of any content transmitted through the Services.
        • (iv) Misrepresent your affiliation with any person or entity.
        • (v) Act in a manner that negatively affects other users' ability to use the Services.
        • (vi) Engage in activities that violate any applicable laws or regulations, or that give rise to civil liability.
        • (vii) Post content that is unlawful, obscene, defamatory, threatening, harassing, abusive, slanderous, hateful, or embarrassing to any other person as determined by DOM FOOD in its sole discretion.
        • (viii) Post advertisements or solicitations of business.</p>
      <p><br/></p>
      <p>7.5 Enforcement and Termination: DOM FOOD reserves the right to take appropriate action against any user for unauthorized use of the Services. This includes, but is not limited to, civil, criminal, and injunctive remedies and the termination of the user's account. If DOM FOOD determines that your conduct or content damages its reputation or goodwill, DOM FOOD may refuse to provide its Services to you and pursue any necessary legal remedies.</p>
      <p><br/></p>
      <p>7.6 Feedback: DOM FOOD values your insights and encourages you to share your ideas, suggestions, and proposals to help us improve our Services ("Feedback"). Feedback can be submitted through our designated channels, including forums, email, or feedback forms on our website.
        • (a) Voluntary and Unconfidential: You acknowledge and agree that any Feedback provided is voluntary and will not be considered confidential. DOM FOOD has no obligations regarding such Feedback, including but not limited to confidentiality obligations.
        • (b) License to Feedback: By submitting Feedback, you grant DOM FOOD a perpetual, irrevocable, worldwide, royalty-free license to use, reproduce, display, perform, adapt, modify, transmit, and distribute the Feedback for any purpose, including the development, manufacturing, and marketing of products and services, and creation of derivative works.     • (c) No Obligation for Compensation: DOM FOOD may use your Feedback at its sole discretion without any obligation to compensate you or provide acknowledgment.
        • (d) No Confidential Information: Ensure that your Feedback does not include confidential information or proprietary ideas unless specifically agreed otherwise in writing with DOM FOOD. DOM FOOD will not be bound by any confidentiality obligations concerning such Feedback.
        • (e) Acknowledgment of Similar Content: By submitting Feedback, you acknowledge that DOM FOOD may develop content similar to your Feedback, and you waive any claims regarding similarities.</p>
      <p className="text-xl font-bold mt-4 mb-4">8. Communications with DOM FOOD</p>
      <p><br/></p>
      <p>8.1 Consent to Electronic Communications: By creating a DOM FOOD account, you consent to receive electronic communications from DOM FOOD, Contractors, or third parties providing services to DOM FOOD. This includes emails, text messages, phone calls, and push notifications sent to the cellular telephone number you have provided. These communications may be generated by automated telephone dialing systems and/or may include prerecorded messages related to your service activities, such as order notifications. Please be aware that message and data rates may apply.</p>
      <p><br/></p>
      <p>8.2 Opting Out of Promotional Communications: If you do not wish to receive promotional communications, you can adjust your preferences in the 'Settings' section of your account. To opt out of receiving promotional text messages, reply with "STOP" from the mobile device that received the messages. Note that text messages related to delivery transactions and service-related notifications are considered transactional rather than promotional and will continue to be sent to you as necessary for the performance of our services.</p>
      <p><br/></p>
      <p>8.3 Management of Preferences: You can manage your communication preferences and update your contact information through the 'Settings' section of your DOM FOOD account. This allows you to tailor the type and frequency of communications you receive from DOM FOOD to better suit your preferences.
      </p>
      <p><br/></p>
      <p>8.4 Encouragement of Feedback: DOM FOOD values your feedback and encourages you to provide insights and suggestions to help us improve our services. We use this feedback to enhance our offerings and ensure a better user experience. Regular updates regarding new features, services, and special offers will be communicated as appropriate, always respecting your communication preferences and privacy.</p>
      <p><br/></p>
      <p>8.5 Privacy and Respect: DOM FOOD is committed to maintaining open channels of communication while respecting your privacy and preferences. We ensure that all communications comply with applicable laws and regulations and that your privacy is protected in accordance with our Privacy Policy.
      </p>
      <p><br/></p>
      <p>8.6 Changes to Communication Preferences: If you change your communication preferences or contact information, please update your account settings promptly to ensure that we have the most current information. DOM FOOD will make reasonable efforts to honor your updated preferences in a timely manner.</p>
      <p className="text-xl font-bold mt-4 mb-4">9. E-SIGN Disclosure</p>
      <p><br/></p>
      <p>9.1 Consent to Electronic Records: By creating a DOM FOOD account, you consent to the use of electronic records to document and communicate your agreement with DOM FOOD. This means that all agreements, disclosures, and notices related to your account and use of the Services will be provided to you electronically.
      </p>
      <p><br/></p>
      <p>9.2 Withdrawing Consent: If you wish to withdraw your consent to receive electronic records, you may do so at any time. To withdraw consent, please email DOM FOOD at privacy@domfood.com with "Revoke Electronic Consent" in the subject line. Please note that withdrawing consent may affect your ability to use certain features of the Services that require electronic documentation.</p>
      <p><br/></p>
      <p>9.3 Viewing and Retaining Disclosure: To view and retain a copy of this disclosure, you will need:
        • (i) A device (such as a computer or mobile phone) with a web browser and Internet access.
        • (ii) Either a printer to print this disclosure or sufficient storage space on your device to save an electronic copy.</p>
      <p><br/></p>
      <p>9.4 Requesting a Paper Copy: If you require a free paper copy of this disclosure, or if you need to update your contact information in our records, please contact us at privacy@domfood.com with your contact details and a request for a paper copy. We will provide the paper copy and update your records as requested.</p>
      <p><br/></p>
      <p>9.5 Updating Contact Information: It is important to keep your contact information current in our records to ensure that you receive all notices, disclosures, and communications promptly. Please update your contact details whenever there are changes to avoid disruptions in service and ensure effective communication.</p>
      <p><br/></p>
      <p>9.6 Technical Requirements: Ensure that your device meets the technical requirements to access and use electronic records effectively. This includes having a compatible web browser and sufficient storage space.</p>
      <p><br/></p>
      <p className="text-xl font-bold mt-4 mb-4">10. Intellectual Property Ownership</p>
      <p><br/></p>
      <p>10.1 Ownership: DOM FOOD, including its licensors where applicable, exclusively owns all rights, title, and interest in and to the Software and the Services, including all related intellectual property rights. This ownership includes, but is not limited to, copyrights, trademarks, patents, and trade secrets.</p>
      <p><br/></p>
      <p>10.2 No Transfer of Ownership Rights: This Agreement does not constitute a sale and does not convey any rights of ownership in or related to the Software, the Services, or any intellectual property rights owned by DOM FOOD. You are granted only a limited, non-exclusive, non-transferable license to use the Software and Services in accordance with the terms of this Agreement.</p>
      <p><br/></p>
      <p>10.3 Trademarks: The names, logos, and product names associated with the Software and Services are trademarks of DOM FOOD or third parties. No right or license is granted to you to use these trademarks without the prior written consent of DOM FOOD or the respective trademark owner. This includes any use of DOM FOOD’s trademarks or third-party trademarks in a manner that suggests an endorsement or affiliation with DOM FOOD.
      </p>
      <p><br/></p>
      <p>10.4 Protection of Intellectual Property: You agree not to remove, alter, or obscure any copyright, trademark, service mark, or other proprietary rights notices that are incorporated into or accompany the Software or the Services. All such notices must remain intact and visible as part of the Software and Services.</p>
      <p><br/></p>
      <p>
        10.5 Feedback and Contributions: Any feedback, suggestions, or contributions you provide regarding the Software or Services will be considered non-confidential and will become the property of DOM FOOD. DOM FOOD shall have the right to use, reproduce, and incorporate such feedback and contributions into the Software and Services without any obligation or compensation to you.</p>
      <p><br/></p>
      <p>10.6 Enforcement: DOM FOOD reserves the right to enforce its intellectual property rights to the fullest extent permitted by law, including pursuing legal action for infringement or unauthorized use of its intellectual property.</p>
      <p><br/></p>
      <p className="text-xl font-bold mt-4 mb-4">11. Payment Terms</p>
      <p><br/></p>
      <p>11.1 Prices & Charges: You acknowledge that the prices for items displayed through the Services may differ from the prices offered or published by merchants for the same items and/or from prices available on third-party websites. Prices through the Services may not always represent the lowest available price. DOM FOOD reserves the right to adjust the prices for services and goods offered through the Services at any time and at its sole discretion. For certain transactions, the subtotal shown at checkout is an estimate and may be adjusted based on the final in-store totals. In such cases, DOM FOOD reserves the right to charge your payment method the final price after checkout. You are responsible for all transaction taxes related to the services provided under this Agreement, excluding taxes based on DOM FOOD’s income.</p>
      <p><br/></p>
      <p>11.2 No Refunds: Charges paid for completed and delivered orders are final and non-refundable. DOM FOOD has no obligation to provide refunds or credits, but may do so at its sole discretion. Refunds or credits, if granted, will be subject to DOM FOOD’s policies and the specifics of the transaction.</p>
      <p><br/></p>
      <p>11.3 Updates and Changes: DOM FOOD may change the prices and features of its services, including subscription services, without prior notice. If there are any fee changes for subscriptions, DOM FOOD will notify you, providing an opportunity to cancel or modify your subscription before the next billing cycle. Continued use of the Services after any fee change constitutes your acceptance of the new terms. If you are dissatisfied with the fee changes, you may cancel your subscription within the notice period specified in your subscription agreement.</p>
      <p><br/></p>
      <p>11.4 Payment Disputes and Inquiries: If you believe there has been an error in any charge associated with your account, or if you need customer support related to billing, please contact DOM FOOD’s customer service department immediately to resolve the issue. This includes discrepancies in charges, subscription fees, or gift card usage. DOM FOOD will make reasonable efforts to address and resolve any billing issues promptly.</p>
      <p><br/></p>
      <p>11.5 No Transfer or Assignments & Cancellations by DOM FOOD: Your subscription is personal and non-transferable. You may not transfer or assign your subscription or account to any other individual or entity without the prior written consent of DOM FOOD. DOM FOOD reserves the right to suspend or terminate your subscription or service at any time, for any reason, at its sole discretion. If DOM FOOD cancels your subscription due to non-compliance with the Agreement, no refund will be issued.</p>
      <p><br/></p>
      <p>11.6 Gift Cards: Gift Cards purchased through DOM FOOD may be redeemed towards eligible orders placed on www.domfood.com or through the DOM FOOD mobile app. Gift Cards are not redeemable for cash, except as required by law, and have no expiration date. For comprehensive terms and conditions regarding Gift Cards, please visit our website.</p>
      <p><br/></p>
      <p>
        11.7 Customer Support: For questions or concerns regarding your account, transactions, or subscriptions, please contact our customer support at support@domfood.com. Our dedicated team is available to assist you with any issues related to the use of DOM FOOD’s services.</p>
      <p><br/></p>
      <p className="text-xl font-bold mt-4 mb-4">12. Dispute Resolution</p>
      <p><br/></p>
      <p>PLEASE READ THE FOLLOWING SECTION CAREFULLY. IT REQUIRES YOU TO ARBITRATE DISPUTES WITH DOM FOOD AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF. THIS SECTION SHALL BE REFERRED TO AS THE “ARBITRATION AGREEMENT”.</p>
      <p><br/></p>
      <p>12.1 Scope of Arbitration Agreement: You agree that any dispute or claim related in any way to your access or use of the Services as a User, any advertising or marketing communications regarding DOM FOOD or the Services, any products or services sold or distributed through the Services, or any aspect of your relationship or transactions with DOM FOOD will be resolved through binding arbitration rather than in court. This includes, but is not limited to, claims arising from your use of the Services or disputes regarding the quality or delivery of goods and services.</p>
      <p><br/></p>
      <p>12.2 Exclusions from Arbitration: You may assert claims in small claims court if your claims qualify and remain on an individual basis. For claims related to intellectual property infringement, either you or DOM FOOD may seek relief in court.</p>
      <p><br/></p>
      <p>12.3 Class Action Waiver: By agreeing to this Arbitration Agreement, you waive your right to participate in any class action, collective, or representative lawsuit. You agree to pursue any claims on an individual basis only. This means you cannot participate in or seek relief through class action litigation or similar collective proceedings.
      </p>
      <p><br/></p>
      <p>12.4 Informal Resolution: Both you and DOM FOOD agree to make good-faith efforts to resolve disputes informally before resorting to arbitration. This process is intended to provide a prompt, cost-effective, and mutually satisfactory resolution. Before initiating arbitration, both parties must attempt to resolve the dispute informally through a dispute resolution conference.
        • (a) Initiation of Informal Dispute Resolution: The party initiating the dispute must provide written notice to the other party to initiate an informal dispute resolution conference. This conference should take place within 60 days of the notice, unless extended by mutual agreement. To notify DOM FOOD of your intent to start the informal dispute resolution process, email InformalResolution@domfood.com with your name, the telephone number associated with your account, the email address associated with your account, and a description of your claim.
        • (b) Conducting the Conference: During the informal dispute resolution period, both parties are encouraged to attempt to resolve the dispute amicably. You may be represented by counsel, who can participate in the conference, but you must also participate fully. Engaging in this informal resolution process is a prerequisite to commencing arbitration.
      </p>
      <p><br/></p>
      <p>12.5 Tolling of Statute of Limitations: The statute of limitations and any filing fee deadlines will be suspended while the parties engage in the informal dispute resolution process as required by this section.</p>
      <p><br/></p>
      <p>12.6 Arbitration Process: If the dispute is not resolved informally, it will be submitted to binding arbitration in accordance with the rules of an agreed-upon arbitration body. The arbitration will be conducted on an individual basis, and no class, collective, or representative arbitration will be allowed. The arbitrator’s decision will be final and binding, subject to any rights of appeal available under applicable law.</p>
      <p><br/></p>
      <p>12.7 Fees and Costs: Each party will bear its own costs and expenses related to the arbitration, including attorney’s fees, unless otherwise required by law or agreed upon by the parties. The costs of the arbitration process will be allocated as determined by the arbitrator.</p>
      <p><br/></p>
      <p>12.8 Confidentiality: The arbitration proceedings, including any settlement, will be confidential. The parties agree to keep all aspects of the arbitration confidential, except as required by law or for purposes of enforcement of the arbitration award.</p>
      <p><br/></p>
      <p className="text-xl font-bold mt-4 mb-4">13. Social Media Guidelines</p>
      <p><br/></p>
      <p>13.1 Purpose and Scope: DOM FOOD maintains various social media pages to engage with the DOM FOOD community and provide updates, support, and interaction opportunities. By posting, commenting, or otherwise interacting on these pages, you agree to adhere to our Social Media Community Guidelines, which are intended to foster a respectful, constructive, and positive environment for all participants.</p>
      <p><br/></p>
      <p>13.2 Community Guidelines:
        • (a) Respectful Interaction: Engage in discussions constructively and politely. Avoid offensive language, personal attacks, or derogatory comments. We encourage meaningful and respectful conversations.
        • (b) Prohibition of Offensive Content: Do not post content that is discriminatory, harassing, threatening, obscene, or otherwise offensive. This includes any material that targets individuals or groups based on race, ethnicity, nationality, religion, gender, sexual orientation, disability, or any other protected characteristic.
        • (c) Intellectual Property Rights: Respect the intellectual property rights of others. Do not post content that infringes on copyrights, trademarks, patents, or any other intellectual property rights. Share only content you own or have permission to use.
        • (d) Privacy and Confidentiality: Do not share personal or confidential information about others without their explicit consent. Respect the privacy of other users and refrain from posting sensitive or private information.
        • (e) Constructive Feedback: If you have suggestions or feedback, provide them constructively. Focus on solutions and improvements rather than criticizing without offering potential solutions.</p>
      <p><br/></p>
      <p>13.3 Enforcement and Consequences:
        • (a) Content Removal: DOM FOOD reserves the right to remove any content that violates these guidelines or is deemed inappropriate, harmful, or disruptive to the community.
        • (b) Participation Ban: Users who repeatedly violate these guidelines may be banned from participating on our social media pages. The duration of such bans will be determined at DOM FOOD's discretion.
        • (c) Other Actions: DOM FOOD may take additional actions as deemed necessary to address violations, including reporting to social media platforms, legal actions, or other measures to protect the community.</p>
      <p><br/></p>
      <p>13.4 Reporting Violations: If you encounter any content or behavior that you believe violates these guidelines, please report it to DOM FOOD using the reporting tools provided on our social media pages or contact our support team at support@domfood.com. We will review and address such reports in accordance with our policies.
      </p>
      <p><br/></p>
      <p>13.5 Appreciation and Cooperation: We appreciate your cooperation in helping to maintain a safe, inclusive, and engaging environment on our social media pages. Your adherence to these guidelines contributes to a positive experience for everyone in the DOM FOOD community.</p>
      <p><br/></p>
      <p>13.6 Updates to Guidelines: DOM FOOD reserves the right to update these Social Media Community Guidelines as necessary. Changes will be posted on our social media pages, and continued use of our social media platforms signifies your acceptance of any modifications.</p>
      <p><br/></p>
    </div>
  )
}

export default TermsAndConditions
