import Bes from '../images/Bes.png'
import Mon from '../images/Monet.png'
import Auto from '../images/Auto.png'
import Bez from '../images/Bez.png'
import FooderImage from '../images/fooder.png'
import Cover from "../images/cover.png"

const gridItemStyle = {
  backgroundImage: `url(${Cover})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  mixBlendMode: 'soft-light',
  position: 'relative'
}

const foodersDataOne = [
  {title: 'Бесплатно', icon: Bes, subtitle: 'Бесплатная регистрация и самые низкие комиссии. Увеличьте свой доход, предлагая свои блюда широкой аудитории через нашу платформу. Каждая продажа на DomFood - это дополнительные деньги в ваш карман.'},
  {title: 'Монетизация', icon: Mon, subtitle: 'Получайте заказы, готовьте с удовольствием, и зарабатывайте больше.'}
]

const foodersDataTwo = [
  {title: 'Автоматизация', icon: Auto, subtitle: 'Размещайте свои блюда на платформе и привлекайте новых клиентов.'},
  {title: 'Без затрат на рекламу', icon: Bez, subtitle: 'Забудьте о дорогостоящей рекламе! Команда DomFood занимается.'}
]

const ForFooder = () => {
  return (
    <div style={gridItemStyle} className="w-full h-[80%]">
      <div className="flex w-full justify-center mt-[35px]">
        <div className='w-[315px] text-center'>
        <p className="text-center text-3xl gradient-text">Для поваров</p>
        </div>
      </div>
    <div className="flex px-[110px] justify-between items-center">
      <div>
        {foodersDataOne.map((item,index) => {
          return (
            <div className="w-[350px] items-center flex flex-col" key={index}>
                <img src={item.icon} alt="svg"/>
                <p className="font-semibold text-xl">{item.title}</p>
                <p className="mt-4">{item.subtitle}</p>
            </div>
          )
        })}
      </div>
      <div>
        <img src={FooderImage} alt="png" />
      </div>
      <div>
        {foodersDataTwo.map((item,index) => {
          return (
            <div className="w-[350px] items-center flex flex-col" key={index}>
              <img src={item.icon} alt="svg"/>
              <p className="font-semibold text-xl">{item.title}</p>
              <p className="mt-4">{item.subtitle}</p>
            </div>
          )
        })}
      </div>
    </div>
    </div>
  )
}

export default ForFooder