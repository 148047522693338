import BlockOne from "../components/BlockOne";
import About from "../components/About";
import ForFooder from "../components/ForFooder";
import HowItsWork from "../components/HowItsWork";
import QuestionsBlock from "../components/QuetionsBlock";

const Home  = () => {
  return (
    <div>
      <BlockOne />
      <About />
      <ForFooder />
      <HowItsWork />
      <QuestionsBlock />
    </div>
  )
}

export default Home