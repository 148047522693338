import BlockOneImage from '../images/blockOne.png'
import MapImage from '../images/mapImage.png'
import Call from "./Call";

const gridItemStyle = {
  backgroundImage: `url(${BlockOneImage})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  mixBlendMode: 'soft-light',
  position: 'relative'
}

const BlockOne = () => {
  return (
    <div className="w-full h-[79%] mt-20" style={gridItemStyle}>
      <div className="flex items-center px-[165px]">
        <div className="text-start">
          <h2 className="text-5xl text-[#333F51] text-nowrap font-bold font-custom">Присоединяйтесь к платформе</h2>
          <h1 className="text-6xl text-[#DE4333] font-custom font-bold mt-[14px]">DomFood БЕСПЛАТНО</h1>
          <p className="text-[#404F65] text-2xl font-normal text-nowrap mt-[10px]">И откройте новые возможности для вашего кулинарного бизнеса!</p>
          <div className="mt-10">
            <p className="text-[#404F65] font-normal text-2xl"><span className="text-[#DE4333]">DomFood</span> - это уникальное приложение для</p>
            <p className="text-[#404F65] text-2xl">доставки домашней еды</p>
          </div>
          <button className="mt-3.5	rounded-[30px] h-[52px] bg-gradient-to-r from-[#DE4333] to-[#FDA110] text-white px-10">Оставить заявку</button>
        </div>
        <div>
          <img src={MapImage} alt="png" />
        </div>
      </div>
      <Call />
    </div>
  )
}

export default BlockOne