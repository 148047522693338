import Image1 from './../images/image1.png'
import image2 from './../images/image2.png'
import image3 from './../images/image3.png'
import image4 from './../images/image4.png'
import CoverHow from "../images/coverHow.png";

const data = [
  {icon: Image1, title: 'Заполнение заявки', subtitle: 'Перейдите на сайт DomFood и заполните форму регистрации, указав основную информацию о себе и своем кулинарном бизнесе.'},
  {icon: image2, title: 'Создание меню', subtitle: 'Разработайте и загрузите ваше меню на платформу. Мы берем на себя фотоссесию Ваших блюд и предоставляем Вам эту услугу бесплатно. Добавьте описания блюд, фотографии и цены.'}
]

const dataTwo = [
  {icon: image3, title: 'Загрузка документов', subtitle: 'Предоставьте необходимые документы для подтверждения вашей квалификации и соблюдения санитарных норм.'},
  {icon: image4, title: 'Начало приема заказов', subtitle: 'После одобрения вашей заявки, вы можете начинать принимать заказы и готовить вкусные блюда для наших клиентов. Наши курьеры доставят до клиентра'}
]

const steps = [
  1,2,3,4
]

const gridItemStyle = {
  backgroundImage: `url(${CoverHow})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  position: 'relative'
}

const HowItsWork = () => {
  return (
    <div className="px-[95px] bg-[#ffffff]">
     <div style={gridItemStyle} className="flex mt-40 px-[95px] justify-around py-[115px]">
      <div>
        {data.map((item,index) => {
          return (
            <div className="w-[315px] flex items-center flex-col" key={index}>
              <img src={item.icon} alt="svg" />
              <p className='gradient-text text-2xl mt-[10px]'>{item.title}</p>
              <p className="text-lg	text-[#333333] mt-[10px]">{item.subtitle}</p>
            </div>
          )
        })}
      </div>
      <div className="flex flex-col justify-between">
        {steps.map((item,index) => {
          return (
            <div key={index} className="flex flex-col items-end">
              <div className="flex items-center">
                {<div className="w-[160px] h-[1px] bg-gradient-to-r from-[#DE4333] to-[#FDA110]"></div> }
                <div className="w-[50px] h-[50px] rounded-full bg-gradient-to-r from-[#DE4333] to-[#FDA110] flex items-center justify-center text-white" key={item}>
                  {item}
                </div>
              </div>
              {steps.length - 1 !== index && <div className="mr-[24px] w-[1px] h-[160px] bg-gradient-to-r from-[#DE4333] to-[#FDA110]"></div>}
            </div>
          )
        })}
      </div>
      <div>
        {dataTwo.map((item,index) => {
          return (
            <div className="w-[315px] flex items-center flex-col" key={index}>
              <img src={item.icon} alt="svg" />
                <p className='gradient-text text-2xl mt-[10px]'>{item.title}</p>
              <p className="text-lg	text-[#333333] mt-[10px]">{item.subtitle}</p>
            </div>
          )
        })}
      </div>
    </div>
    </div>
  )
}

export default HowItsWork

