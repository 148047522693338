import './App.css';
import Header from './components/Header';
import Footer from "./components/Footer";
import { Routes, Route } from "react-router-dom"
import TermsAndConditions from "./pages/TermsAndConditions";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermServices from "./pages/TermServices";

function App() {
  return (
    <div className="max-w-[1680px] m-auto w-full h-[100vh]">
      <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms_and_conditions" element={<TermsAndConditions />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/terms_of_service" element={<TermServices />} />
        </Routes>
      <Footer />
    </div>
  );
}

export default App;
