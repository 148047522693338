import Mobile from './../images/mobile.png'
import Clock from './../images/clock.png'
import Arrow from './../images/arrow.png'

const About = () => {
  return (
    <div className="bg-[#ffffff] flex px-44 grid grid-cols-5 items-center">
          <div className="flex items-center justify-center col-span-2 bg-gradient-to-r from-[#DE4333] to-[#FDA110] rounded-full w-[455px] h-[455px]">
            <img className="h-[507px]" src={Mobile} alt="png" />
          </div>
          <div className="col-span-1 flex flex-col h-full justify-between">
            <img className="w-[80px] h-[80px] relative" src={Clock} alt="png" />
            <img className="w-[80px] h-[150px]" src={Arrow} alt="png" />
          </div>
         <div className="col-span-2 text-start">
          <p className="text-[28px] gradient-text">О нас</p>
          <p className="text-2xl font-custom"><span className="from-[#DE4333] to-[#FDA110] gradient-text">DomFood</span> — это уникальное приложение для доставки домашней еды в Казахстане, где</p>
           <p className="text-xl mt-3 font-custom">талантливые повара из городов <span className="font-bold">Атырау,</span> <span className="font-bold">Актау,</span> <span className="font-bold">Астана и </span>  <span className="font-bold">Алматы</span> предлагают свои
            блюда. С DomFood вы можете наслаждаться вкусной и свежей домашней едой,
            приготовленной с любовью и профессионализмом, не выходя из дома.</p>
        </div>
    </div>
  )
}

export default About
