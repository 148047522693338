import { useState } from "react";
import { Disclosure } from '@headlessui/react';
import ArrowLeft from './../images/arrowLeft.png';
import ArrowTop from './../images/arrowTop.png';

const data = [
  {
    title: 'Как осуществляется бесплатная регистрация на платформе DomFood?',
    subtitle: 'Регистрация на DomFood полностью бесплатна для всех поваров. Чтобы зарегистрироваться, вам нужно перейти на наш сайт и заполнить форму заявки, указав основную информацию о себе и своем кулинарном бизнесе. После этого необходимо предоставить подтверждающие документы для проверки вашей квалификации и соблюдения санитарных норм. Как только ваша заявка будет одобрена, вы сможете создать свое меню и начать принимать заказы. Никаких скрытых платежей или комиссий за регистрацию и использование платформы нет.'
  },
  {
    title: 'Какие юридические обязательства возникают при использовании платформы DomFood?',
    subtitle: 'Регистрация на DomFood полностью бесплатна для всех поваров. Чтобы зарегистрироваться, вам нужно перейти на наш сайт и заполнить форму заявки, указав основную информацию о себе и своем кулинарном бизнесе. После этого необходимо предоставить подтверждающие документы для проверки вашей квалификации и соблюдения санитарных норм. Как только ваша заявка будет одобрена, вы сможете создать свое меню и начать принимать заказы. Никаких скрытых платежей или комиссий за регистрацию и использование платформы нет.'
  },
  {
    title: 'В каких городах действует платформа DomFood?',
    subtitle: 'Регистрация на DomFood полностью бесплатна для всех поваров. Чтобы зарегистрироваться, вам нужно перейти на наш сайт и заполнить форму заявки, указав основную информацию о себе и своем кулинарном бизнесе. После этого необходимо предоставить подтверждающие документы для проверки вашей квалификации и соблюдения санитарных норм. Как только ваша заявка будет одобрена, вы сможете создать свое меню и начать принимать заказы. Никаких скрытых платежей или комиссий за регистрацию и использование платформы нет.'
  },
  {
    title: 'Как работает курьерская служба DomFood?',
    subtitle: 'Регистрация на DomFood полностью бесплатна для всех поваров. Чтобы зарегистрироваться, вам нужно перейти на наш сайт и заполнить форму заявки, указав основную информацию о себе и своем кулинарном бизнесе. После этого необходимо предоставить подтверждающие документы для проверки вашей квалификации и соблюдения санитарных норм. Как только ваша заявка будет одобрена, вы сможете создать свое меню и начать принимать заказы. Никаких скрытых платежей или комиссий за регистрацию и использование платформы нет.'
  },
];

const QuestionsBlock = () => {
  const [openItems, setOpenItems] = useState([]);

  const toggleItem = (index) => {
    setOpenItems((prevOpenItems) => {
      const updatedOpenItems = [...prevOpenItems];
      updatedOpenItems[index] = !updatedOpenItems[index];
      return updatedOpenItems;
    });
  };

  return (
    <div className="mt-9 space-y-10 w-full px-[272px]">
      <div className="flex justify-center">
        <div className="w-[500px] text-nowrap">
          <p className="gradient-text text-3xl font-bold">Часто задаваемые вопросы</p>
        </div>
      </div>
      {data.map((orderInner, index) => {
        const isOpen = openItems[index] || false;
        return (

          <Disclosure className="rounded-2xl shadow-md p-4 md:p-6" key={index} as="div">
            <Disclosure.Button
              onClick={() => toggleItem(index)}
              as="div"
              className="flex items-center justify-between w-full max-h-[90px] md:max-h-full cursor-pointer"
            >
              <p className="text-[#333F51] font-bold text-xl">
                {orderInner.title}
              </p>
              <div>
                <img
                  className=""
                  src={isOpen ? ArrowTop : ArrowLeft}
                  alt="arrow icon"
                />
              </div>
            </Disclosure.Button>
            <Disclosure.Panel
              style={{ whiteSpace: 'pre-line' }}
              className="text-sm text-gray-500 mt-4"
            >
              <div className="text-base leading-7 font-medium max-w-[800px]">
                <p className="text-[#556987]">{orderInner.subtitle}</p>
              </div>
            </Disclosure.Panel>
          </Disclosure>
        );
      })}
    </div>
  );
};

export default QuestionsBlock;
