import { NavLink } from "react-router-dom";

const data = [
  {title: 'Правила и условия', href: 'terms_and_conditions'},
  {title: 'Политика конфиденциальности', href: 'privacy_policy'},
  {title: 'Условия обслуживания', href: 'terms_of_service'},
]
const Footer = () => {
  return (
    <div className="bg-[#f7f8f9] px-[180px] py-[20px]">
      <nav>
        <ul className="flex gap-x-4">
          {data.map(({ href, title }) => (
            <li className='m-2' key={title}>
              <NavLink to={href} >
                <p className={'text-black'}>{title}</p>
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  )
}

export default Footer
