import {useEffect} from "react";

const TermServices = () => {

      useEffect(() => {
            window.scrollTo(10, 10)
      }, [])

      return (
    <div className="px-56 my-20">
      <h1 className="text-center font-bold text-3xl">Terms of Service</h1>
      <h3 className="font-bold text-2xl mt-4">END USER LICENSE AGREEMENT</h3>
      <h3 className="font-bold text-xl mt-4">Last updated: September 04, 2024</h3>
      <p className="mt-14">DOM FOOD is licensed to you (End-User) by DOM FOOD LLC, located and registered at 34 Chaykovski Street 19, Yerevan, Yerevan 0010, Republic of Armenia ("Licensor"), for use only under the terms of this License Agreement.</p>

<p>By downloading the Licensed Application from Apple's software distribution platform ("App Store") or Google's software distribution platform ("Play Store"), and any updates thereto (as permitted by this License Agreement), you indicate that you agree to be bound by all terms and conditions of this License Agreement and accept this License Agreement. The App Store and Play Store are referred to in this License Agreement as "Services."</p>
      <p>The parties to this License Agreement acknowledge that the Services are not a party to this License Agreement and are not bound by any provisions or obligations with regard to the Licensed Application, such as warranty, liability, maintenance, and support thereof. DOM FOOD LLC, not the Services, is solely responsible for the Licensed Application and its content.</p>
      <p>This License Agreement may not provide for usage rules for the Licensed Application that are in conflict with the latest Apple Media Services Terms and Conditions and Google Play Terms of Service ("Usage Rules"). DOM FOOD LLC acknowledges that it has reviewed the Usage Rules and confirms that this License Agreement does not conflict with them.</p>
      <p>DOM FOOD, when purchased or downloaded through the Services, is licensed to you solely under the terms of this License Agreement. The Licensor reserves all rights not expressly granted to you. DOM FOOD is to be used on devices operating with Apple's operating systems ("iOS" and "Mac OS") or Google's operating system ("Android").</p>
      <h3 className="font-bold text-xl mt-4 mb-4">TABLE OF CONTENTS</h3>
<p>1. THE APPLICATION
  2. SCOPE OF LICENSE
  3. TECHNICAL REQUIREMENTS
  4. MAINTENANCE AND SUPPORT
  5. USE OF DATA
  6. USER-GENERATED CONTRIBUTIONS
  7. CONTRIBUTION LICENSE
  8. LIABILITY
  9. WARRANTY
  10. PRODUCT CLAIMS
  11. LEGAL COMPLIANCE
  12. CONTACT INFORMATION
  13. TERMINATION
  14. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY
  15. INTELLECTUAL PROPERTY RIGHTS
  16. APPLICABLE LAW
  17. MISCELLANEOUS
</p>
      <p><br/></p>
      <p className="text-xl font-bold mt-4">1. Acceptance of this Agreement</p>
      <p><br/></p>
      <p className="my-4">DOM FOOD ("Licensed Application") is a piece of software designed to simplify the process of ordering and delivering food. It is customized for use on iOS and Android mobile devices ("Devices") and serves to connect users with local restaurants and chefs, facilitating the delivery of orders directly to users' doorsteps.</p>
      <p className="my-4">The Licensed Application is not tailored to comply with industry-specific regulations such as the Health Insurance Portability and Accountability Act (HIPAA) or the Federal Information Security Management Act (FISMA). Therefore, if your use of the application involves compliance with such regulations, you may not use this Licensed Application. Additionally, you may not use the Licensed Application in any manner that would violate the Gramm-Leach-Bliley Act (GLBA).</p>
      <p className="my-4">By using the Licensed Application, you agree to adhere to all applicable laws and regulations in your jurisdiction. It is your responsibility to ensure that your use of the application complies with any relevant legal requirements.</p>
      <p className="my-4">DOM FOOD LLC disclaims any liability for your failure to comply with applicable laws or regulations while using the Licensed Application. You are solely responsible for ensuring that your use of the application does not infringe upon or violate any legal requirements or industry-specific regulations.</p>
      <p className="my-4">If you are using the Licensed Application in a context where additional legal or regulatory compliance is required, please consult with a legal professional to ensure that you meet all necessary obligations.</p>
      <p className="text-xl font-bold mt-4">Your continued use of the Licensed Application indicates your acceptance of these terms and conditions.</p>
      <p className="text-xl font-bold mt-4">2. SCOPE OF LICENSE</p>
      <p><br/></p>
      <p>2.1 License Grant: You are granted a non-transferable, non-exclusive, non-sublicensable license to install and use the Licensed Application on any Devices that you (End-User) own or control, as permitted by the Usage Rules. This license also allows the Licensed Application to be accessed and used by other accounts associated with you (End-User, The Purchaser) through Family Sharing or volume purchasing.</p>
      <p><br/></p>
      <p>2.2 Updates: This license governs any updates to the Licensed Application provided by the Licensor that replace, repair, or supplement the original Licensed Application. If a separate license is provided for such updates, the terms of that new license will apply.</p>
      <p><br/></p>
      <p>2.3 Restrictions on Use: You may not share, distribute, or make the Licensed Application available to third parties (unless expressly allowed by the Usage Rules and with DOM FOOD LLC's prior written consent). You may not sell, rent, lend, lease, or redistribute the Licensed Application.</p>
      <p><br/></p>
      <p>2.4 Prohibitions: You are prohibited from reverse engineering, translating, disassembling, integrating, decompiling, modifying, combining, creating derivative works of, adapting, or attempting to derive the source code of the Licensed Application, or any part thereof, without DOM FOOD LLC's prior written consent.</p>
      <p><br/></p>
      <p>2.5 Copying and Backup: You may not copy the Licensed Application (except as expressly authorized by this license and the Usage Rules) or alter any parts of it. You are allowed to create and store copies solely on devices that you own or control for backup purposes in accordance with this license, the Usage Rules, and any other applicable terms and conditions related to the device or software used. You must not remove any intellectual property notices. Copies must not be accessible to unauthorized third parties. If you sell your Devices to a third party, you must delete the Licensed Application from the Devices beforehand.</p>
      <p><br/></p>
      <p>2.6 Enforcement and Violations: Violations of the above obligations, as well as attempts to violate them, may result in legal action and damages. DOM FOOD LLC reserves the right to enforce these terms and seek remedies as permitted by law.</p>
      <p><br/></p>
      <p>2.7 Modifications: The Licensor reserves the right to modify the terms and conditions of this license agreement at any time. Such modifications will be effective as specified in the revised license agreement.</p>
      <p><br/></p>
      <p>2.8 Third-Party Terms: Nothing in this license should be interpreted to restrict third-party terms. When using the Licensed Application, you must ensure that you comply with applicable third-party terms and conditions.</p>
      <p><br/></p>
<p>2.9 Compliance with Local Laws: Users agree to comply with all local laws regarding online conduct and acceptable content when using the Licensed Application. This includes adhering to regulations and legal standards in your jurisdiction.</p>
      <p><br/></p>
      <p>2.10 Indemnification: You agree to indemnify and hold DOM FOOD LLC harmless from any claims or demands, including reasonable attorneys' fees, made by any third party due to or arising out of your breach of this Agreement, your improper use of DOM FOOD's Services, or your breach of any law or the rights of a third party.</p>
      <p><br/></p>
      <p>2.11 Force Majeure: DOM FOOD LLC is not liable for any failure to perform due to causes beyond its reasonable control, including but not limited to natural disasters, governmental acts or omissions, laws or regulations, terrorism, strikes or labor disputes, communications system breakdowns, hardware or software failures, transportation stoppages or slowdowns, or the inability to procure supplies or materials.</p>
      <p><br/></p>
      <p>2.12 Transferability: This license is granted to you and is non-transferable. If you sell or transfer the Device on which the Licensed Application is installed, you must remove the Licensed Application from the Device before transferring it. If you are a business and you transfer your rights to another entity, you must ensure that the new entity complies with all terms of this Agreement.</p>
      <p><br/></p>
      <p>2.13 Termination Conditions: DOM FOOD LLC may terminate this license immediately if you fail to comply with any of the terms and conditions of this Agreement. Upon termination, you must cease all use of the Licensed Application and destroy all copies of it.</p>
      <p><br/></p>
      <p className="text-xl font-bold mt-4">3. TECHNICAL REQUIREMENTS</p>
      <p><br/></p>
      <p>3.1 Firmware and Software Requirements: The Licensed Application requires a firmware version 1.0.0 or higher. To ensure optimal performance, compatibility, and security, the Licensor recommends using the latest version of the firmware available for your device.</p>
      <p><br/></p>
      <p>3.2 Updates and Compatibility: The Licensor makes efforts to keep the Licensed Application updated to ensure compatibility with modifications, new versions of firmware, and new hardware. While updates are provided according to the Licensor's update strategy, you are not entitled to demand such updates. The availability and timing of updates are at the discretion of the Licensor.</p>
      <p><br/></p>
      <p>3.3 User Responsibility: You acknowledge that it is your responsibility to verify and ensure that the device on which you intend to use the Licensed Application meets the technical specifications mentioned above. This includes ensuring that your device's hardware, operating system, and firmware are compatible with the Licensed Application.</p>
      <p><br/></p>
      <p>3.4 Modification of Technical Specifications: The Licensor reserves the right to modify the technical specifications of the Licensed Application at any time. Such modifications may be made to improve functionality, enhance performance, or comply with updated security standards. Users will be informed of any significant changes that could impact the use of the Licensed Application.</p>
      <p><br/></p>
      <p>3.5 Device Requirements: It is essential to ensure that your device meets the necessary hardware, storage, and Internet connection requirements as updated from time to time. These requirements are crucial to support the full functionality of the Licensed Application and to ensure a smooth user experience.</p>
      <p><br/></p>
      <p>3.6 Performance and Liability: Failure to maintain your device according to the required specifications may result in suboptimal performance of the Licensed Application. The Licensor is not responsible for any issues related to performance or functionality caused by non-compliance with the technical requirements or specifications.</p>
      <p><br/></p>
      <p>3.7 Support for Technical Issues: If you experience technical issues related to the Licensed Application, please refer to our support resources or contact us using the information provided in the Contact Information section. We may provide guidance on ensuring your device meets the necessary requirements or assist with troubleshooting.</p>
      <p><br/></p>
      <p className="text-xl font-bold mt-4">4. MAINTENANCE AND SUPPORT</p>
      <p><br/></p>


      <h4>4.1 Maintenance and Support Services</h4>
      <p>DOM FOOD LLC is solely responsible for providing maintenance and support services for the Licensed Application. If you require assistance or support, you can contact DOM FOOD LLC at the email address provided in the App Store or Play Store overview for this Licensed Application. Support services include, but are not limited to, handling technical issues, providing bug fixes, and implementing performance improvements in accordance with DOM FOOD LLC's standard policies.</p>
      <p><br/></p>
      <h4>4.2 Platform Providers’ Responsibilities</h4>
      <p>DOM FOOD LLC and the End-User acknowledge that the Services (App Store and Play Store) have no obligation to provide maintenance or support services for the Licensed Application. Any issues, support requests, or inquiries regarding the Licensed Application should be directed exclusively to DOM FOOD LLC. The platform providers are not responsible for addressing or resolving such issues.</p>
      <p><br/></p>
      <h4>4.3 Service Availability and Limitations</h4>
      <p>While DOM FOOD LLC strives to maintain the Licensed Application in optimal condition, the Licensor does not guarantee uninterrupted or error-free operation. The End-User acknowledges that occasional interruptions in service or access may occur due to maintenance, updates, or unforeseen technical issues. DOM FOOD LLC will make reasonable efforts to minimize such disruptions and promptly address any service interruptions.</p>
      <p><br/></p>
      <h4>4.4 Reporting Issues</h4>
      <p>If you encounter any problems with the Licensed Application, please report them using the contact details provided. DOM FOOD LLC will assess and address reported issues as part of its support services. You are encouraged to provide detailed information about any problems to facilitate effective resolution.</p>
      <p><br/></p>
      <h4>4.5 Feedback and Improvements</h4>
      <p>We welcome feedback on the Licensed Application to help us improve our services. You may submit feedback or suggestions through the contact details provided. While we may not be able to respond to all feedback individually, we consider user input in our ongoing development and enhancement efforts.</p>
      <p><br/></p>
      <p className="text-xl font-bold mt-4">5. USE OF DATA</p>
      <p><br/></p>

      <h4>5.1 Access and Adjustment of Data</h4>
      <p>You acknowledge that DOM FOOD LLC may access and modify the content of your downloaded Licensed Application and your personal information. The use of such material and information by DOM FOOD LLC is governed by your legal agreements with DOM FOOD LLC and our Privacy Policy, which can be reviewed at the bottom of the Licensed Application.</p>
      <p><br/></p>
      <h4>5.2 Collection of Technical Data</h4>
      <p>DOM FOOD LLC may periodically collect and use technical data and related information about your device, system, application software, and peripherals. This data collection serves various purposes, including:</p>
      <ul>
        <li><strong>Offering product support:</strong> Assisting you with technical issues and troubleshooting.</li>
        <li><strong>Facilitating software updates:</strong> Ensuring that your application remains current with the latest features and security patches.</li>
        <li><strong>Providing related services:</strong> Enhancing the functionality and performance of the Licensed Application.</li>
      </ul>
      <p><br/></p>
      <h4>5.3 Improvement and Personalization</h4>
      <p>DOM FOOD LLC may use the collected technical data to improve its products and services, as well as to offer you personalized experiences. This information is used in a form that does not personally identify you, thereby safeguarding your privacy.</p>
      <p><br/></p>
      <h4>5.4 Privacy and Security Measures</h4>
      <p>DOM FOOD LLC is committed to protecting your privacy and has implemented appropriate security measures to safeguard your data. We strive to ensure that your information is secure and used only for the purposes outlined in our Privacy Policy.</p>
      <p><br/></p>
      <h4>5.5 Rights to Your Data</h4>
      <p>You have the right to access, correct, or delete your personal data upon request. To exercise these rights, please contact us using the contact information provided below. We will respond to your requests in accordance with applicable data protection laws.</p>
      <p><br/></p>
      <h4>5.6 Data Sharing with Third Parties</h4>
      <p>DOM FOOD LLC may share your data with third parties only as necessary to provide services related to the Licensed Application. Such sharing will be conducted under conditions that ensure your data remains protected. For more information on how your data is shared and protected, please refer to our Privacy Policy.</p>
      <p><br/></p>
      <h4>5.7 Contact for Concerns</h4>
      <p>If you have any concerns about how your data is handled, or if you need further information about our data practices, please contact us at <a href="mailto:support@domfood.com">support@domfood.com</a>. We are here to address your inquiries and ensure your data privacy.</p>
      <p><br/></p>
      <p className="text-xl font-bold mt-4">6. USER-GENERATED CONTRIBUTIONS</p>
      <p><br/></p>
<p>The Licensed Application may offer you the opportunity to chat, contribute to, or participate in blogs, message boards, online forums, and other functionalities. It may also allow you to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or within the Licensed Application, including but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or other material (collectively, "Contributions"). Contributions may be viewable by other users of the Licensed Application and through third-party websites or applications. As such, any Contributions you transmit may be treated as non-confidential and non-proprietary. By creating or making available any Contributions, you represent and warrant that:</p>
      <p><br/></p>

      <h4>1. Intellectual Property Rights</h4>
      <p>The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your Contributions do not infringe the proprietary rights of any third party, including but not limited to copyright, patent, trademark, trade secret, or moral rights.</p>
      <p><br/></p>
      <h4>2. Ownership and Licensing</h4>
      <p>You are the creator and owner of, or have the necessary licenses, rights, consents, releases, and permissions to use and authorize us, the Licensed Application, and other users to use your Contributions in any manner contemplated by the Licensed Application and this License Agreement.</p>
      <p><br/></p>
      <h4>3. Consent for Identifiable Individuals</h4>
      <p>You have obtained the written consent, release, and/or permission of each identifiable individual person included in your Contributions to use their name or likeness in any manner contemplated by the Licensed Application and this License Agreement.</p>
      <p><br/></p>
      <h4>4. Accuracy</h4>
      <p>Your Contributions are not false, inaccurate, or misleading.</p>
      <p><br/></p>
      <h4>5. Solicitation</h4>
      <p>Your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation.</p>
      <p><br/></p>
      <h4>6. Content Standards</h4>
      <p>Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or otherwise objectionable (as determined by us).</p>
      <p><br/></p>
      <h4>7. Respectfulness</h4>
      <p>Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.</p>
      <p><br/></p>
      <h4>8. Harassment and Violence</h4>
      <p>Your Contributions are not used to harass or threaten any person and do not promote violence against a specific person or class of people.</p>
      <p><br/></p>
      <h4>9. Legal Compliance</h4>
      <p>Your Contributions do not violate any applicable law, regulation, or rule.</p>
      <p><br/></p>
      <h4>10. Privacy and Publicity Rights</h4>
      <p>Your Contributions do not violate the privacy or publicity rights of any third party.</p>
      <p><br/></p>
      <h4>11. Protection of Minors</h4>
      <p>Your Contributions do not violate any applicable law concerning child pornography or otherwise intended to protect the health or well-being of minors.</p>
      <p><br/></p>
      <h4>12. Offensive Content</h4>
      <p>Your Contributions do not include any offensive comments related to race, national origin, gender, sexual preference, or physical handicap.</p>
      <p><br/></p>
      <h4>13. Compliance with Agreement</h4>
      <p>Your Contributions do not otherwise violate, or link to material that violates, any provision of this License Agreement or any applicable law or regulation.</p>
      <p><br/></p>
      <h4>14. Monitoring and Moderation</h4>
      <p>DOM FOOD LLC reserves the right to monitor, review, and moderate user-generated content. We may remove or disable access to any content that violates this Agreement or is otherwise objectionable, at our sole discretion.</p>
      <p><br/></p>
      <h4>15. Indemnification</h4>
      <p>You agree to indemnify and hold DOM FOOD LLC harmless from any claims, damages, liabilities, costs, or expenses (including reasonable attorneys' fees) arising from or related to your Contributions.</p>
      <p><br/></p>
      <p>Any use of the Licensed Application in violation of the foregoing terms constitutes a breach of this License Agreement and may result in, among other things, the termination or suspension of your rights to use the Licensed Application.</p>
      <p><br/></p>
      <p className="text-xl font-bold mt-4">7. CONTRIBUTION LICENSE</p>
      <p><br/></p>
      <p>By posting your Contributions to any part of the Licensed Application or making Contributions accessible by linking your account from the Licensed Application to any of your social networking accounts, you automatically grant, and represent and warrant that you have the right to grant, to DOM FOOD LLC an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right and license to:</p>
      <p><br/></p>
      <p>    • Host, use, copy, reproduce, disclose, sell, resell, publish, broadcast, retitle, archive, store, cache, publicly display, reformat, translate, transmit, excerpt (in whole or in part), and distribute such Contributions. This includes, without limitation, your image and voice, for any purpose, including commercial advertising or otherwise.
        • Prepare derivative works of, or incorporate the Contributions into other works.
        • Grant and authorize sublicenses of the foregoing rights.</p>
      <p><br/></p>
      <p>The use and distribution of Contributions may occur in any media formats and through any media channels, whether now known or hereafter developed. This includes our use of your name, company name, franchise name (if applicable), and any trademarks, service marks, trade names, logos, and personal and commercial images you provide.</p>
      <p><br/></p>
      <p>You waive all moral rights in your Contributions and warrant that such rights have not otherwise been asserted.

        DOM FOOD LLC does not claim ownership over your Contributions. You retain full ownership of all your Contributions and any intellectual property rights or other proprietary rights associated with them. DOM FOOD LLC is not liable for any statements or representations made in your Contributions within the Licensed Application. You are solely responsible for your Contributions and expressly agree to hold DOM FOOD LLC harmless from any and all responsibility, and to refrain from any legal action against DOM FOOD LLC regarding your Contributions.</p>
      <p><br/></p>
      <p>DOM FOOD LLC reserves the right, in its sole and absolute discretion, to:
        1 Edit, redact, or otherwise modify any Contributions.
        2 Recategorize any Contributions to place them in more appropriate locations within the Licensed Application.
        3 Prescreen or delete any Contributions at any time and for any reason, without notice.
        4 Implement content moderation practices to ensure compliance with this Agreement.</p>
      <p><br/></p>
      <p>Users can report any violations or inappropriate content by using the reporting tools provided within the Licensed Application. DOM FOOD LLC will review such reports and take appropriate actions, which may include removing or disabling access to the offending content.</p>
      <p><br/></p>
      <p>DOM FOOD LLC is not responsible for content on third-party websites that may be linked to or included in your Contributions. We do not endorse and are not liable for any content or practices of third-party sites.</p>
      <p><br/></p>
      <p className="text-xl font-bold mt-4">8. LIABILITY</p>
      <p><br/></p>
      <h4>8.1 Limitation of Liability</h4>
      <p>DOM FOOD LLC's liability for any violation of obligations or tort claims shall be limited to cases of intent and gross negligence. In the event of a breach of essential contractual duties (cardinal obligations), DOM FOOD LLC will also be liable in cases of slight negligence. However, in all cases, liability will be limited to foreseeable and contractually typical damages. This limitation does not apply to injuries resulting in loss of life, bodily harm, or health issues. The total liability of DOM FOOD LLC for any claims arising out of this Agreement shall be limited to the amount paid by you for the Licensed Application during the 12-month period immediately preceding the date the claim arose.</p>
      <p><br/></p>
      <h4>8.2 Data Loss and Backup</h4>
      <p>DOM FOOD LLC assumes no responsibility for any damages arising from breaches of duties related to Section 2 of this License Agreement. To prevent data loss, you are required to use the backup functions of the Licensed Application to the extent permitted by applicable third-party terms and conditions. You acknowledge that any alterations or manipulations of the Licensed Application may result in loss of access or functionality, and DOM FOOD LLC is not liable for such losses.</p>
      <p><br/></p>
      <h4>8.3 Exclusion of Certain Damages</h4>
      <p>DOM FOOD LLC shall not be liable for any consequential, incidental, indirect, or special damages, including but not limited to lost profits, loss of data, or business interruption, arising out of or related to the use or inability to use the Licensed Application, even if DOM FOOD LLC has been advised of the possibility of such damages. This exclusion of liability applies to all forms of damages, whether direct or indirect.</p>
      <p><br/></p>
      <h4>8.4 Indemnification</h4>
      <p>You agree to indemnify, defend, and hold harmless DOM FOOD LLC, its affiliates, and their respective officers, directors, employees, and agents from any claims, damages, liabilities, losses, costs, or expenses, including reasonable attorneys' fees, arising out of or in connection with your use of the Licensed Application or any violation of this Agreement. This indemnification obligation includes any claims brought by third parties related to your actions or omissions while using the Licensed Application.</p>
      <p><br/></p>
      <p className="text-xl font-bold mt-4">9. WARRANTY</p>
      <p><br/></p>

      <h4>9.1 Warranty Coverage</h4>
      <p>DOM FOOD LLC warrants that the Licensed Application is free of spyware, trojan horses, viruses, or any other malware at the time of your download. Additionally, DOM FOOD LLC warrants that the Licensed Application will function as described in the user documentation.</p>
      <p><br/></p>
      <h4>9.2 Exclusions from Warranty</h4>
      <p>No warranty is provided for the Licensed Application if it becomes non-executable on your device, has been unauthorizedly modified, handled inappropriately or negligently, combined or installed with inappropriate hardware or software, or used with incompatible accessories, whether by you or third parties. This includes any issues arising from reasons outside of DOM FOOD LLC's control.</p>
      <p><br/></p>
      <h4>9.3 Inspection and Reporting</h4>
      <p>You are required to inspect the Licensed Application immediately upon installation. Any issues or defects discovered must be reported to DOM FOOD LLC without delay. Reports of defects should be emailed to the address provided in the Contact Information section within 5 days of discovery. Reports submitted outside this period may not be considered.</p>
      <p><br/></p>
      <h4>9.4 Remedy for Defects</h4>
      <p>If DOM FOOD LLC confirms that the Licensed Application is defective, it reserves the right to remedy the situation either by correcting the defect or providing a substitute delivery of the Licensed Application. The remedy will be provided in accordance with DOM FOOD LLC's policies.</p>
      <p><br/></p>
      <h4>9.5 Refunds</h4>
      <p>In the event that the Licensed Application fails to conform to any applicable warranty, you may notify the Services Store Operator to request a refund. The refund will be limited to the purchase price of the Licensed Application. To the maximum extent permitted by applicable law, the Services Store Operator will have no other warranty obligation with respect to the Licensed Application, and DOM FOOD LLC will not be liable for any additional losses, claims, damages, liabilities, expenses, or costs related to warranty issues.</p>
      <p><br/></p>
      <h4>9.6 Statutory Limitation Period</h4>
      <p>For users who are entrepreneurs, any warranty claims based on defects will expire after a statutory limitation period of twelve (12) months from the date the Licensed Application was made available to the user. Statutory limitation periods provided by law will apply for users who are consumers.</p>
      <p><br/></p>
      <h4>9.7 Disclaimer of Additional Warranties</h4>
      <p>Except for the explicit warranties provided herein, DOM FOOD LLC disclaims all other warranties, express or implied. This includes but is not limited to implied warranties of merchantability or fitness for a particular purpose.</p>
      <p><br/></p>
      <h4>9.8 Limitation of Liability for Third-Party Software</h4>
      <p>DOM FOOD LLC is not responsible for any defects or issues arising from third-party software or hardware used in conjunction with the Licensed Application. Any problems related to third-party components are outside the scope of this warranty.</p>
      <p><br/></p>
      <h4>9.9 Procedure for Warranty Claims</h4>
      <p>For any warranty claims, please follow the instructions provided in the user documentation or contact DOM FOOD LLC at the provided email address. DOM FOOD LLC will respond to warranty claims within a reasonable timeframe and inform you of any necessary steps to address the issue.</p>
      <p className="text-xl font-bold mt-4">10. PRODUCT CLAIMS</p>
      <p><br/></p>
      <h4>10.1 Responsibility for Claims</h4>
      <p>DOM FOOD LLC and the End-User acknowledge that DOM FOOD LLC, and not the Services (App Store or Play Store), is responsible for addressing any claims made by the End-User or any third party related to the Licensed Application or the End-User’s possession and/or use of the Licensed Application. This includes, but is not limited to, the following types of claims:</p>
      <ul>
        <li><strong>Product Liability Claims:</strong> Claims related to product defects, safety issues, or other liability concerns associated with the Licensed Application.</li>
        <li><strong>Compliance Claims:</strong> Claims that the Licensed Application fails to conform to any applicable legal or regulatory requirements.</li>
        <li><strong>Consumer Protection and Privacy Claims:</strong> Claims arising under consumer protection, privacy, or similar legislation, including those related to the Licensed Application’s use of HealthKit and HomeKit.</li>
      </ul>
      <p><br/></p>
      <h4>10.2 Contact for Claims</h4>
      <p>For any product claims, please contact DOM FOOD LLC at the email address provided in the Contact Information section. We will review and address your claims in accordance with our policies.</p>
      <p><br/></p>
      <h4>10.3 Exclusion of Third-Party Claims</h4>
      <p>DOM FOOD LLC is not responsible for claims related to third-party software or services that are integrated with the Licensed Application. Any issues arising from third-party components are outside the scope of DOM FOOD LLC’s responsibility.</p>
      <p><br/></p>
      <h4>10.4 Limitation of Liability</h4>
      <p>To the maximum extent permitted by applicable law, DOM FOOD LLC’s liability for any product claims is limited to the amount paid by you for the Licensed Application. This excludes any indirect, incidental, or consequential damages. DOM FOOD LLC is not liable for damages beyond the purchase price of the Licensed Application.</p>
      <p><br/></p>
      <h4>10.5 Governing Law</h4>
      <p>This section and any claims related to it will be governed by the laws of the Republic of Armenia, excluding its conflict of law principles. Any disputes arising from this section shall be resolved in accordance with Armenian law.</p>
      <p><br/></p>

      <p className="text-xl font-bold mt-4">11. LEGAL COMPLIANCE</p>
      <p><br/></p>

      <h4>11.1 Compliance with Embargoes and Sanctions</h4>
      <p>You represent and warrant that you are not located in a country that is subject to a U.S. Government embargo or has been designated by the U.S. Government as a "terrorist-supporting" country. Additionally, you confirm that you are not listed on any U.S. Government list of prohibited or restricted parties.</p>
      <p><br/></p>
      <h4>11.2 Compliance with Local Laws</h4>
      <p>You agree to comply with all applicable local laws and regulations of the Republic of Armenia in relation to your use of the Licensed Application. This includes adherence to laws governing online conduct, data protection, and any other relevant regulations.</p>
      <p><br/></p>
      <h4>11.3 Violation of Laws</h4>
      <p>If you are found to be in violation of any applicable laws, including but not limited to those related to export controls, sanctions, and anti-terrorism regulations, DOM FOOD LLC reserves the right to take appropriate actions. These actions may include terminating your access to the Licensed Application and pursuing any other legal remedies available.</p>
      <p><br/></p>
      <h4>11.4 Termination and Actions</h4>
      <p>In the event of a violation of applicable laws or regulations, DOM FOOD LLC may, at its sole discretion, suspend or terminate your access to the Licensed Application. DOM FOOD LLC may also take any other actions deemed necessary to ensure compliance with legal requirements and to protect its interests.</p>
      <p><br/></p>
      <h4>11.5 Changes to Compliance Requirements</h4>
      <p>DOM FOOD LLC reserves the right to modify its compliance requirements as necessary to comply with changes in laws and regulations. Any such modifications will be communicated to you through updates to this Agreement or other appropriate means.</p>
      <p><br/></p>
      <h4>11.6 Responsibility for Legal Compliance</h4>
      <p>You are responsible for understanding and adhering to all applicable legal and regulatory requirements in your jurisdiction. Failure to comply with these requirements may result in legal consequences and the suspension or termination of your access to the Licensed Application.</p>
      <p><br/></p>
      <p className="text-xl font-bold mt-4">12. CONTACT INFORMATION</p>
      <p><br/></p>
      <h4>Contact Information</h4>
      <p>For general inquiries, complaints, questions, or claims concerning the Licensed Application, please contact us using the following information:</p>

      <p><strong>Email:</strong> <a href="mailto:info@domfood.com">info@domfood.com</a><br/>
        For inquiries related to the Licensed Application, technical support, or any other questions.</p>

      <p><strong>Postal Address:</strong><br/>
        DOM FOOD LLC<br/>
        34 Chaykovski Street 19<br/>
        Yerevan, Yerevan 0010<br/>
        Republic of Armenia</p>

      <p>We aim to respond to your inquiries and address your concerns in a timely manner. For specific issues related to the Licensed Application, please provide detailed information to help us assist you effectively.</p>

      <p>If you have any concerns about data privacy or need to exercise your rights related to personal data, please refer to our<a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Privacy Policy</a> and contact us through the provided email address.</p>

      <p>For urgent matters or issues requiring immediate attention, please use the email contact for quicker responses.</p>
      <p className="text-xl font-bold mt-4">13. TERMINATION</p>
      <p><br/></p>

      <h5>13.1 Termination of License</h5>
      <p>The license granted under this Agreement is valid until terminated by DOM FOOD LLC or by you. Your rights under this license will automatically terminate without notice from DOM FOOD LLC if you fail to adhere to any term(s) of this license agreement or if you breach any of its provisions.</p>
      <p><br/></p>
      <h5>13.2 Actions Upon Termination</h5>
      <p>Upon termination of the license, you must immediately cease all use of the Licensed Application and destroy all copies, whether full or partial, of the Licensed Application. This includes removing the Licensed Application from all Devices and storage locations under your control.</p>
      <p><br/></p>
      <h5>13.3 Certification of Destruction</h5>
      <p>Following termination, you must certify to DOM FOOD LLC in writing that all copies of the Licensed Application have been destroyed or removed from your Devices and storage. This certification should be sent to DOM FOOD LLC using the contact information provided in the Contact Information section.</p>
      <p><br/></p>
      <h5>13.4 Enforcement and Legal Action</h5>
      <p>DOM FOOD LLC reserves the right to take legal action if necessary to enforce compliance with this termination provision. This may include seeking remedies or damages resulting from any failure to comply with the termination requirements.</p>
      <p><br/></p>
      <h5>13.5 Survival of Provisions</h5>
      <p>The termination of this license does not affect the validity of any other provisions of this Agreement that, by their nature, are intended to survive termination, including but not limited to provisions related to intellectual property rights, liability limitations, and indemnification.</p>
      <p><br/></p>
      <h5>13.6 Post-Termination Access</h5>
      <p>After termination, any access you had to the Licensed Application, including any associated accounts or data, will be terminated. You will no longer be able to access or use the Licensed Application or any related services.</p>
      <p><br/></p>
      <p className="text-xl font-bold mt-4">14. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY</p>
      <p><br/></p>

      <h5>14.1 Compliance with Third-Party Terms</h5>
      <p>DOM FOOD LLC represents and warrants that it will comply with all applicable third-party terms of agreement when using the Licensed Application. This includes adhering to terms set forth by platform providers and any other relevant third parties that interact with or support the Licensed Application.</p>
      <p><br/></p>
      <h5>14.2 Third-Party Beneficiaries</h5>
      <p>In accordance with Section 9 of the "Instructions for Minimum Terms of Developer's End-User License Agreement," both Apple Inc. and Google LLC, and their respective subsidiaries, are recognized as third-party beneficiaries of this End-User License Agreement. By accepting the terms and conditions of this License Agreement, you acknowledge and agree that both Apple and Google have the right to enforce this End-User License Agreement against you as third-party beneficiaries.</p>
      <p><br/></p>
      <h5>14.3 Enforcement Rights</h5>
      <p>As third-party beneficiaries, Apple and Google can directly enforce certain provisions of this Agreement and seek remedies available to them under this Agreement. This is in addition to any rights or claims that DOM FOOD LLC may have under this Agreement. Specifically, Apple and Google may exercise rights to enforce compliance with terms related to the distribution platforms they provide, including the App Store and Play Store.</p>
      <p><br/></p>
      <h5>14.4 Scope of Enforcement</h5>
      <p>The enforcement rights of Apple and Google as third-party beneficiaries include, but are not limited to, ensuring compliance with terms related to app performance, user rights, and platform-specific regulations. They may also take action to address violations or non-compliance affecting their platforms or services.</p>
      <p><br/></p>
      <h5>14.5 Notification of Changes</h5>
      <p>If any changes are made to the third-party terms or conditions that affect this Agreement, DOM FOOD LLC will notify you and ensure that such changes are communicated to Apple and Google as required. Compliance with updated third-party terms is essential to maintaining the functionality and availability of the Licensed Application.</p>
      <p><br/></p>

      <p className="text-xl font-bold mt-4">15. INTELLECTUAL PROPERTY RIGHTS</p>
      <p><br/></p>

      <h5>15.1 Responsibility for Claims</h5>
      <p>DOM FOOD LLC and the End-User acknowledge that, in the event of any third-party claim alleging that the Licensed Application or the End-User's possession and use of the Licensed Application infringes on any third party's intellectual property rights, DOM FOOD LLC will be solely responsible for the investigation, defense, settlement, and resolution of such claims. The Services (App Store or Play Store) are not responsible for handling or addressing these claims.</p>
      <p><br/></p>
      <h5>15.2 Indemnification by DOM FOOD LLC</h5>
      <p>DOM FOOD LLC agrees to indemnify, defend, and hold the End-User harmless from any claims, losses, liabilities, damages, costs, or expenses (including reasonable attorneys' fees) arising out of or related to any third-party claims of intellectual property infringement related to the Licensed Application. This indemnification obligation covers all reasonable costs associated with defending against such claims and any settlements or judgments that may arise.</p>
      <p><br/></p>
      <h5>15.3 End-User Obligations</h5>
      <p>The End-User agrees to promptly notify DOM FOOD LLC of any third-party claims related to intellectual property infringement. Additionally, the End-User agrees to cooperate fully with DOM FOOD LLC in the defense of such claims, including providing necessary documentation and information, and participating in the defense process as reasonably requested.</p>
      <p><br/></p>
      <h5>15.4 Process for Handling Claims</h5>
      <p>Upon receiving notice of a third-party claim, DOM FOOD LLC will undertake the investigation and defense of the claim. DOM FOOD LLC will have the authority to manage the defense, including settlement negotiations, and the End-User agrees to adhere to DOM FOOD LLC’s decisions and actions in this regard. The End-User will not independently settle or resolve any claim without DOM FOOD LLC’s prior written consent.</p>
      <p><br/></p>
      <h5>15.5 Exclusions</h5>
      <p>This indemnification does not apply to claims arising from the End-User’s unauthorized modifications of the Licensed Application, use of the Licensed Application in combination with other products not provided or approved by DOM FOOD LLC, or any other misuse of the Licensed Application that contributes to the claim of infringement.</p>
      <p><br/></p>
      <p className="text-xl font-bold mt-4">16. APPLICABLE LAW</p>
      <p><br/></p>

      <h5>16.1 Governing Law</h5>
      <p>This License Agreement is governed by and construed in accordance with the laws of the Republic of Armenia, excluding its conflict of law principles. This means that the laws of the Republic of Armenia will apply to interpret and enforce this Agreement, without regard to any conflicts of laws rules that might otherwise apply.</p>
      <p><br/></p>
      <h5>16.2 Jurisdiction</h5>
      <p>Any disputes arising out of or in connection with this License Agreement, including disputes related to its formation, interpretation, performance, or termination, shall be subject to the exclusive jurisdiction of the courts located in the Republic of Armenia. This means that only the courts in the Republic of Armenia will have the authority to resolve any legal issues or disputes related to this Agreement.</p>
      <p><br/></p>
      <h5>16.3 Dispute Resolution</h5>
      <p>In the event of a dispute, the parties agree to attempt to resolve the matter through informal discussions or negotiation before resorting to formal legal proceedings. If informal resolution is not possible, the parties will proceed to court as specified in this section.</p>
      <p><br/></p>
      <h5>16.4 Enforcement</h5>
      <p>Any legal action or proceeding arising out of or related to this Agreement must be brought in the competent courts of the Republic of Armenia. The parties consent to the personal jurisdiction of these courts and agree that they are an appropriate venue for resolving disputes.</p>
      <p><br/></p>
      <h5>16.5 Severability</h5>
      <p>If any provision of this section is found to be invalid or unenforceable by a court of competent jurisdiction, the remaining provisions will continue in full force and effect. The parties agree to negotiate in good faith to replace any invalid or unenforceable provision with a valid and enforceable provision that most closely reflects the original intent.</p>
      <p><br/></p>
      <p className="text-xl font-bold mt-4">17. MISCELLANEOUS</p>
      <p><br/></p>

      <h5>17.1 Severability</h5>
      <p>If any provision of this Agreement is or becomes invalid, illegal, or unenforceable, the validity of the remaining provisions shall not be affected. The invalid provision will be replaced with a valid provision that most closely reflects the original intent of the invalid provision, ensuring that the primary purpose of the Agreement is preserved. The replacement provision shall be deemed to be part of this Agreement as if it had been included from the outset.</p>
          <p><br/></p>
      <h5>17.2 Amendments and Changes</h5>
      <p>Collateral agreements, changes, and amendments to this Agreement are only valid if made in writing and signed by authorized representatives of both parties. The requirement for written form cannot be waived except in a written agreement signed by both parties.</p>
          <p><br/></p>
      <h5>17.3 Entire Agreement</h5>
      <p>This Agreement constitutes the entire agreement between the parties concerning the subject matter hereof and supersedes all prior or contemporaneous agreements, proposals, or communications, whether oral or written, regarding such subject matter. No additional terms or conditions shall be binding unless incorporated into this Agreement through a written amendment signed by both parties.</p>
          <p><br/></p>
      <h5>17.4 Force Majeure</h5>
      <p>DOM FOOD LLC shall not be liable for any failure or delay in the performance of its obligations under this Agreement due to causes beyond its reasonable control, including but not limited to natural disasters, acts of government, war, terrorism, strikes, or other labor disturbances. In such events, DOM FOOD LLC will make reasonable efforts to mitigate the impact of the force majeure event on its performance.</p>
          <p><br/></p>
      <h5>17.5 Assignment</h5>
      <p>This Agreement may not be assigned or transferred by you without the prior written consent of DOM FOOD LLC. DOM FOOD LLC may assign or transfer this Agreement without restriction. Any attempted assignment or transfer by you without proper consent will be null and void.</p>
          <p><br/></p>
      <h5>17.6 Waiver</h5>
      <p>Any waiver of any provision of this Agreement must be in writing and signed by an authorized representative of the party against whom the waiver is sought to be enforced. Failure to enforce any provision of this Agreement shall not constitute a waiver of such provision or any other provision. Waivers are only effective if explicitly stated in writing and signed by the appropriate party.</p>
          <p><br/></p>
      <h5>17.7 Notices</h5>
      <p>All notices required or permitted under this Agreement must be in writing and sent to the addresses specified in the Contact Information section or as otherwise designated by the parties. Notices will be deemed effective upon delivery if delivered in person, or upon receipt if sent by certified mail or email.</p>
          <p><br/></p>
    </div>
  )
}

export default TermServices;
